import * as THREE from 'three'
import { coords, createEnvironment, cvToGlMatrix, getRoot, rotationMatrix } from './utils'

class GL {

  renderer: THREE.WebGLRenderer
  scene: THREE.Scene
  camera: THREE.PerspectiveCamera
  background: THREE.Texture | null = null

  constructor(canvas: HTMLCanvasElement) {
    this.renderer = new THREE.WebGLRenderer({ canvas, antialias: true })
    this.renderer.setPixelRatio(2)
    this.scene = new THREE.Scene()
    this.camera = new THREE.PerspectiveCamera(40, canvas.width/canvas.height, 0.1, 1000)

    createEnvironment(this.renderer, this.scene)
    
    this.onResize()

    canvas.addEventListener("click", this.mouseEffect.bind(this, "onClick"))
  }

  private clock = new THREE.Clock()
  render() {
    if (this.background) this.background.needsUpdate = true
    const delta = this.clock.getDelta()
    
    for (let child of this.scene.children) {
      if (child.userData.playing === false) continue
      const mixer = (child.userData.mixer || child.children[0]?.userData.mixer) as THREE.AnimationMixer
      if (!mixer) continue

      mixer.update(delta)
    }

    this.renderer.render(this.scene, this.camera)
  }
  
  updateCameraMatrix(fov: number, matrix: number[][]) {

    this.camera.matrixAutoUpdate = false
    this.camera.matrix.set(
      matrix[0][0], matrix[0][1], matrix[0][2], matrix[0][3], 
      matrix[1][0], matrix[1][1],	matrix[1][2], matrix[1][3], 
      matrix[2][0], matrix[2][1], matrix[2][2], matrix[2][3], 
    0, 0, 0, 1).premultiply(cvToGlMatrix).multiply(rotationMatrix).invert()
    this.camera.updateMatrixWorld(true)

    this.camera.fov = fov
    this.camera.updateProjectionMatrix()
  }

  initVideoBackground(video: HTMLVideoElement | HTMLImageElement | HTMLCanvasElement) {
    this.background = new THREE.Texture(video)
    this.background.minFilter = THREE.LinearFilter
		this.background.generateMipmaps = false
    this.scene.background = this.background
    this.background.needsUpdate = true
  }

  onResize() {
    const canvas = this.renderer.domElement
    this.renderer.setSize(canvas.width, canvas.height, false)
    this.camera.aspect = this.renderer.domElement.width/this.renderer.domElement.height
    this.camera.updateProjectionMatrix()
  }

  // Дальше методы для рейкастинга
  mouseEffect(eventName: string, e: MouseEvent) {
    const xy = coords({ x: e.clientX, y: e.clientY }, e.currentTarget as HTMLCanvasElement)
    const object = this.raycast(xy)
    if (object && object.userData[eventName]) {
      object.userData[eventName]()
    }
  }
  
  raycast({ x, y }: { x: number, y: number }): THREE.Object3D | null {

    const raycaster = new THREE.Raycaster();

    const pointer = new THREE.Vector2(x, y)
    raycaster.setFromCamera(pointer, this.camera)    
    const intersects = raycaster.intersectObjects(this.scene.children.filter(gl => gl.userData.onClick), true)
    
    if (intersects.length === 0) return null
     
    return getRoot(intersects[0].object)
  }
}

export default GL