import { initCanvas } from "../libs/camera"
import { hypot, multiply } from "mathjs"
import QR from "./qr"
// @ts-ignore
import jsfeat from 'jsfeat'
import { bufferToArray, bufferToPoints, decompose, getCameraMatrix, distance } from "../libs/decompose"
import { coordMultiply, sqrHypot } from "libs/math"

const point_status = new Uint8Array(4)
const homo_kernel = new jsfeat.motion_model.homography2d();

const homo_transform = new jsfeat.matrix_t(3, 3, jsfeat.F32_t | jsfeat.C1_t)
const affine_transform = new jsfeat.matrix_t(3, 3, jsfeat.F32_t | jsfeat.C1_t)

const one = [
	[ 1, 0, 0 ],
	[ 0, 1, 0 ],
	[ 0, 0, 1 ]
]

const cornerPoints = [
	[ -0.5, 0.5, 0, 1 ],
	[ 0.5, 0.5, 0, 1 ],
	[ 0.5, -0.5, 0, 1 ],
	[ -0.5, -0.5, 0, 1 ],
]

type Point = {
	x: number,
	y: number
}

type ImageProcessorCallbackProps = {
	fps: number, 
	imageData: ImageData, 
	matrix: number[][] | null, 
	homography: number[][] | null, 
	points: Point[] | null,
	data: string,
	fovAngle: number
}

class ImageProcessor {

	delay = 0
	lastTimeQuery = 0
	lastTime = 0
	qr = new QR()
	width = 0
	height = 0
	stopFlag = false
	corners: Point[] | null = null

	video!: HTMLVideoElement | HTMLImageElement
	getImageData!: (video: HTMLVideoElement | HTMLImageElement) => ImageData
	canvas!: HTMLCanvasElement
	initialPoints!: Point[]
	cameraMatrix!: number[][]
	homography: number[][] | null = null
	data: string = ""

	fovAngle = 68

	options = {
		win_size: 100,
		max_iterations: 300,
		epsilon: 0.01,
		min_eigen: 0.005,
		levels: 6
	}

	curr_img_pyr = new jsfeat.pyramid_t(this.options.levels)
	prev_img_pyr = new jsfeat.pyramid_t(this.options.levels)

	prev_xy = new Float32Array(4*2);
	curr_xy = new Float32Array(4*2);
	
	calcSubHomography: (() => number[][]) | null = null

	async init(video: HTMLVideoElement | HTMLImageElement){

		if (!this.qr.inited) {
			await this.qr.init()
		}
		this.width = (video as HTMLVideoElement).videoWidth || (video as HTMLImageElement).naturalWidth
		this.height = (video as HTMLVideoElement).videoHeight || (video as HTMLImageElement).naturalHeight
		if (this.canvas) {
			this.canvas.width = this.width
			this.canvas.height = this.height
		} else {
			const { getImageData, canvas } = initCanvas(this.width, this.height )
			this.getImageData = getImageData
			this.canvas = canvas
		}
		
		this.video = video

		this.curr_img_pyr = new jsfeat.pyramid_t(this.options.levels)
		this.prev_img_pyr = new jsfeat.pyramid_t(this.options.levels)
		this.curr_img_pyr.allocate(this.width, this.height, jsfeat.U8_t|jsfeat.C1_t)
		this.prev_img_pyr.allocate(this.width, this.height, jsfeat.U8_t|jsfeat.C1_t)

		const scale = 0.5
		this.initialPoints = [
			{ x: -scale, y: scale },
			{ x: scale, y: scale },
			{ x: scale, y: -scale },
			{ x: -scale, y: -scale }
		]

		this.cameraMatrix = getCameraMatrix(this.height, this.width)

		this.lastTime = Date.now()
	}

	canFindQR(){
		return this.lastTimeQuery >= 0 && Date.now() > this.lastTimeQuery+this.delay
	}

	findQR(imageData: ImageData){
		this.lastTimeQuery = -1

		let homoFromHistory: number[][] | null = null
		if (this.homography)
			homoFromHistory = this.homography

		this.qr.findQR(imageData).then((code) => {

			this.lastTimeQuery = Date.now()
			if(code === null) return

			const { 
				bottomLeftFinderPattern, bottomRightAlignmentPattern, topRightFinderPattern, topLeftFinderPattern,
				bottomLeftCorner, bottomRightCorner, topRightCorner, topLeftCorner
			} = code.location
			if(!bottomRightAlignmentPattern) return

			const corners = [ bottomLeftCorner, bottomRightCorner, topRightCorner, topLeftCorner ]
			
			const points = [
				bottomLeftFinderPattern, bottomRightAlignmentPattern, topRightFinderPattern, topLeftFinderPattern
			]
			
			const h1 = hypot(corners[0].x - corners[1].x, corners[0].y - corners[1].y)
			const h2 = hypot(corners[0].x - corners[3].x, corners[0].y - corners[3].y)

			this.options.win_size = Math.floor(Math.max(h1, h2) / 4)
			//console.log(this.options.win_size)

			homo_kernel.run(this.initialPoints, corners, homo_transform, 4)

			const homography = bufferToArray(homo_transform.data, 3, 3)
			// if (homoFromHistory && this.homography) {

			// 	const finalT = multiply(homography, inv(homoFromHistory))
			// 	const time = Date.now()

			// 	this.calcSubHomography = () => {
			// 		let t = (Date.now()-time) / 500
			// 		if (t >= 1) {
			// 			t = 1
			// 			this.calcSubHomography = null
			// 			if (this.homography)
			// 				this.homography = multiply(finalT, this.homography)
						
			// 			return one
			// 		}
			// 		t = EasingFunctions.easeInOutCubic(t)
			// 		const T = add(multiply((1-t), one), multiply((t), finalT)) as number[][]
			// 		return T
			// 	}
			// } else {
				this.calcSubHomography = null
				this.homography = homography
			//}
			
			for(let i = 0; i < 4; i++){
				this.curr_xy[i*2] = points[i].x
				this.curr_xy[i*2+1] = points[i].y
			}
			
			this.delay = 5000
			this.saveFrame(imageData)
			this.swap()

			this.data = code.data
			this.corners = corners

			this.calculateFovAngle()
		})
	}

	saveFrame(imageData: ImageData){
		jsfeat.imgproc.grayscale(imageData.data, this.width, this.height, this.curr_img_pyr.data[0])
		this.curr_img_pyr.build(this.curr_img_pyr.data[0], true)
	}

	swap(){
		const xy = this.curr_xy
		this.curr_xy = this.prev_xy
		this.prev_xy = xy

		const img_pyr = this.curr_img_pyr
		this.curr_img_pyr = this.prev_img_pyr
		this.prev_img_pyr = img_pyr

	}

	computeOpticalFlow(imageData: ImageData){
		if (this.homography === null) return
		this.saveFrame(imageData)
		
		jsfeat.optical_flow_lk.track(
			this.prev_img_pyr, this.curr_img_pyr, 
			this.prev_xy, this.curr_xy, 
			4, 
			this.options.win_size, 
			this.options.max_iterations, 
			point_status, 
			this.options.epsilon, 
			this.options.min_eigen
		)

		for(let i = 0; i < 4; i++)
			if(point_status[i] === 0){
				this.homography = null
				this.delay = 0
				return null
			}
		
		homo_kernel.run(bufferToPoints(this.prev_xy), bufferToPoints(this.curr_xy), affine_transform, 4)

		const T = bufferToArray(affine_transform.data, 3, 3)
		
		const delta = distance (T, one)

		this.lastTime = Date.now()
		
		if (delta > 0.2) {
			this.delay = 0
		}

		this.homography = multiply(T, this.homography)
		this.swap()

		if(delta > 0.6){
			this.homography = null
			return null
		}

		const matrix = decompose(this.homography, this.cameraMatrix)
		return matrix
	}

	getError(angle: number, corners: Point[]) {
		if (!this.homography ) return -1

		const mtx = getCameraMatrix(this.height, this.width, angle)
		let decomposedMatrix = decompose(this.homography, mtx)

		let _mtx = multiply(mtx, decomposedMatrix)

		const points2d = coordMultiply(_mtx, cornerPoints)
		.map(p => ({ x: p[0]/p[2], y: p[1]/p[2] }))

		let error = 0
		for (let i = 0; i < points2d.length; i++) {
			error += sqrHypot(points2d[i].x - corners[i].x, points2d[i].y - corners[i].y)
		}

		return error
	}

	calculateFovAngle() {
		if (!this.homography) return 0

		const _corners = coordMultiply(this.homography, cornerPoints.map(p => [ p[0], p[1], 1 ]))
		.map(p => ({ x: p[0]/p[2], y: p[1]/p[2] }))

		if (this.getError(this.fovAngle, _corners) < 40) return 

		let min = 10
		let max = 100
		while ((max-min) > 5) {
			let x1 = max - (max-min) / 1.618
			let x2 = min + (max-min) / 1.618

			let x1Error = this.getError(x1, _corners) 
			let x2Error = this.getError(x2, _corners) 
			
			if (x1Error > x2Error)
				min = x1
			else
				max = x2
		}

		const resp = (min+max)/2
		if (resp > 98 || resp < 12) return 

		this.fovAngle = resp
	}

	process(callback: (props: ImageProcessorCallbackProps) => void) {
		
		const computeImage = () => {
			const imageData = this.getImageData(this.video)

			if(this.canFindQR()) {
				this.findQR(imageData)
			}

			const fps = 1/(Date.now() - this.lastTime)*1000

			if(this.homography){
				const matrix = this.computeOpticalFlow(imageData)
				const T = this.calcSubHomography? this.calcSubHomography(): one

				if(matrix) callback({ 
					fps, 
					imageData, 
					matrix, 
					homography: multiply(T, this.homography), 
					fovAngle: this.fovAngle,
					points: bufferToPoints(this.prev_xy),
					data: this.data
				})
			}else{
				this.lastTime = Date.now()
				callback({ 
					fps, 
					imageData, 
					homography: null, 
					matrix: null, 
					points: null, 
					fovAngle: this.fovAngle,
					data: "" 
				})
			}
			
			if(!this.stopFlag)
				requestAnimationFrame(computeImage)
		}
		computeImage()
	}

	stop(){
		this.stopFlag = true
	}

	onResize(width: number, height: number) {
		this.canvas.width = width
		this.canvas.height = height
	}
}

export default ImageProcessor