import { action, autorun, makeObservable, observable, reaction } from "mobx"
import GL from "services/gl"
import Project from "./project"
import * as THREE from 'three'
import ImageProcessor from "services/image-processor"

THREE.Cache.enabled = true

class Store {

  currentUuid: string | null = null
  project: Project | null = null
  gl: GL | null = null
  imageProcessor: ImageProcessor | null = null

  constructor() {
    makeObservable(this, {
      currentUuid: observable,
      project: observable,
      setData: action
    })
  }

  init() {
    
  }

  setData(data: string | null) {
    const splited = data && data.split("/")
    const uuid = splited && splited[splited.length-1]

    if (this.currentUuid !== uuid && this.project) {
      this.project.hide()
    }

    if (!uuid) {
      this.currentUuid = null
      return
    }

    if (this.currentUuid !== uuid) {
      this.currentUuid = uuid
      
      if (this.project) {
        if (this.project.uuid === uuid) {
          this.project.show()
          return
        } else {
          this.project.dispose()
          this.project = null
        }
      }

      fetch("/api/cards/"+uuid)
        .then(data => data.json())
        .then(action(project => {
          if (project.error) return
          if (this.currentUuid !== uuid || !this.gl) return
          this.project = new Project(uuid, this.gl)
          this.project.init(project)
        }))
    }
  }

}

export default Store