import { transformMatrix } from "libs/geometry"
import { createImageObject, createModelObject } from "libs/object"
import { getTransparentShader } from "libs/threejs/green-screen-shader"
import { action, makeObservable, observable, runInAction } from "mobx"
import GL from "services/gl"
import * as THREE from 'three'
import playSrc from './../assets/play.png'

type Object = { 
  asset: string,
  type: "cube" | "glb" | "image", 
  position: { x: number, y: number, z: number},
  rotation: number,
  scale: number,
  data?: { url?: string, chromaKey?: boolean, chromaKeyColor?: string, muted?: boolean, openUrlOnBlank?: boolean, playUiButton?: boolean  }
}

type ProjectOpts = {
  data: { objects: Object[] },
  assets: { id: string, src: string, preview: string }[]
}

class Project {

  assets = observable.array<{ id: string, src: string, preview: string, loaded?: boolean, onLoaded?: () => void }>([])
  gl: GL
  objects: THREE.Object3D[] = []
  useUiButton = false
  uuid: string

  constructor(uuid: string, gl: GL) {
    this.uuid = uuid
    this.gl = gl
    makeObservable(this, {
      useUiButton: observable,
      init: action,
      playAr: action.bound
    })
  }

  init(opts: ProjectOpts) {
    console.log(opts)
    this.useUiButton = false
    for (let asset of opts.assets) {
      this.assets.push({ id: asset.id, src: asset.src, preview: asset.preview, loaded: false })
    }
    for (let objData of opts.data.objects) {
      const obj = this.getObj(objData)
      if (!obj) continue
      
      this.gl.scene.add(obj)
      const asset = this.assets.find(asset => asset.id === objData.asset)
      if (!asset || asset.loaded) {
        const matrix = transformMatrix(obj, objData)
        obj.applyMatrix4(matrix)
      } else {
        asset.onLoaded = () => {
          const matrix = transformMatrix(obj, objData)
          obj.applyMatrix4(matrix)
        }
      }
      this.objects.push(obj)
      this.gl.scene.add(obj)
      if (objData.data?.playUiButton) {
        this.useUiButton = true
        obj.userData.playUiButton = true
        obj.userData.playing = false
      }
    }
  }

  hide() {
    for (let obj of this.objects) {
      obj.visible = false
      if (obj.userData.video) {
        obj.userData.video.pause()
      }
    }
  }

  show() {
    for (let obj of this.objects) {
      obj.visible = true
      if (obj.userData.video && obj.userData.playing) {
        obj.userData.video.play()
      }
    }
  }
  
  dispose() {
    for (let obj of this.objects) {
      if (obj.userData.video) {
        obj.userData.video.pause()
        obj.userData.video.src = ""
        obj.userData.video.remove()
      }
      this.gl.scene.remove(obj)
    }
    this.objects = []
  }

  getObj(obj: Object): THREE.Object3D | null {
    if (obj.type === "cube") {
      const material = new THREE.MeshStandardMaterial( { color: 0xffffff } );
      const cubeObject = new THREE.Mesh( new THREE.BoxGeometry(), material );
      return cubeObject
    }

    if (obj.type === "glb") {
      const asset = this.assets.find(asset => asset.id === obj.asset)
      if (!asset) return null
      
      const group = new THREE.Group()
      createModelObject(asset.src).then((model) => {
        group.add(model)
        asset.onLoaded && asset.onLoaded()
        runInAction(() => asset.loaded = true)
      })
      return group
    }

    if (obj.type === "image") {
      const asset = this.assets.find(asset => asset.id === obj.asset)
      if (!asset) return null
      
      const group = new THREE.Group()
      createImageObject(asset.src, asset.src.endsWith("png")).then(image => {
        group.add(image)
        asset.onLoaded && asset.onLoaded()
        runInAction(() => asset.loaded = true)
      })

      const url = obj.data && obj.data.url
      if (url) {
        if (obj.data?.openUrlOnBlank === false) {
          group.userData.onClick = () => { 
            window.location.href = url
          }
        } else {
          group.userData.onClick = () => window.open(url, "_blank")
        }
      } 
      
      return group
    }

    if (obj.type === "video") {
      const asset = this.assets.find(asset => asset.id === obj.asset)
      if (!asset) return null

      const group = new THREE.Group()
      const video = document.createElement('video')
      video.poster = asset.preview
      video.src = asset.src
      video.playsInline = true
      video.loop = true
      video.setAttribute("style", "position: absolute; top: 0; left: 0; opacity: 0; pointer-events: none; max-width: 100vw; z-index: 5;")
      document.body.appendChild(video)
      group.userData.playing = false
      if (obj.data?.muted) {
        video.defaultMuted = true
        video.muted = true
        video.autoplay = true
        group.userData.playing = true
      }
      group.userData.video = video
      
      Promise.all([
        createImageObject(asset.preview),
        (obj.data?.muted || obj.data?.playUiButton)? Promise.resolve(null): createImageObject(playSrc, true)
      ])
      .then(([ videoObject, playButton ]) => {
        group.add(videoObject)
        if (obj.data?.chromaKey && obj.data.chromaKeyColor) {
          const mat = new THREE.ShaderMaterial(getTransparentShader(new THREE.Color(obj.data.chromaKeyColor), videoObject.material.map as THREE.Texture));
          (videoObject as any).material = mat
        }
        asset.onLoaded && asset.onLoaded()
        runInAction(() => asset.loaded = true)

        const play = () => {
          if (playButton && group.userData.playing) {
            video.pause()
            playButton.visible = true
            group.userData.playing = false
            return
          }
          if (playButton){
            playButton.visible = false
            video.play()
          } 
          group.userData.playing = true
          const videoTexture = new THREE.VideoTexture(video)
          setTimeout (() => {
            if (videoObject.material.type === "ShaderMaterial") {
              (videoObject.material as THREE.Material as THREE.ShaderMaterial).uniforms.map.value = videoTexture
            } else {
              videoObject.material.map = videoTexture;
            }
          }, 200)
        }

        if (playButton) {
          playButton.position.y += 0.01
          playButton.scale.set(0.18, 0.18, 0.18)
          group.add(playButton)
          group.userData.onClick = () => play()
        } else if (obj.data?.playUiButton) {
          group.userData.play = () => play()
        } else {
          if (video.readyState === 4) {
            setTimeout(() => play(), 100)
          } else {
            video.addEventListener("loadeddata", () => play(), { once: true })
          }
        }
      })
      return group
    }

    return null
  }


  playAr() {
    this.useUiButton = false
    for (let obj of this.objects) {
      if (!obj.userData.playUiButton) continue
      if (obj.userData.video) {
        obj.userData.video.play()
        obj.userData.play()
      }
      obj.userData.playing = true
    }
  }

}

export default Project