import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

export const createModelObject = async (url: string) => {
  const loader = new GLTFLoader();
  const gltf = await loader.loadAsync(url)

  if (gltf.animations) {
    gltf.scene.userData.animations = gltf.animations
    gltf.scene.userData.mixer = new THREE.AnimationMixer(gltf.scene)
    gltf.animations.forEach((clip: THREE.AnimationClip) => gltf.scene.userData.mixer.clipAction(clip).play())
    gltf.scene.userData.animPlay = true
  }

  return gltf.scene
}

export const createImageObject = async (url: string, transparent = false) => {

  const loader = new THREE.TextureLoader();
  const map = await loader.loadAsync(url)

  const k = 2 / (map.image.width + map.image.height)
  const geometry = new THREE.PlaneGeometry( map.image.width*k, map.image.height*k )
  geometry.rotateX(-90 * THREE.MathUtils.DEG2RAD)

  const material = new THREE.MeshBasicMaterial({ map, transparent, alphaTest: 0.01 })

  const plane = new THREE.Mesh( geometry, material );

  return plane
}