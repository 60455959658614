import { initCamera, waitCameraData } from "libs/camera";
import { decompose, getCameraMatrix } from "libs/decompose";
import { useRef, useState } from "react";
import { useEffect } from "react";
import cn from 'classnames'
import GL from "services/gl";
import ImageProcessor from "services/image-processor";
import './global.sass'
import Store from "store";
import PlayButton from "components/play-button";
import { makeCanvasResizable } from "services/gl/utils";

function App() {

  const [ store ] = useState(() => new Store())
  useEffect(() => store.init(), [ store ])

  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    const onResize = () => {
      const videoElement = videoRef.current!
      const canvasElement = canvasRef.current!
      setTimeout(() => {
        canvasElement.width = videoElement.videoWidth
        canvasElement.height = videoElement.videoHeight
        store.gl?.onResize()
        store.imageProcessor?.init(videoElement).then(() => {
          store.gl?.initVideoBackground(store.imageProcessor!.canvas)
        })
        canvasElement.style.objectFit = "fill"
        setTimeout(() => {
          canvasElement.style.objectFit = "cover"
        }, 10)
      }, 50)
    }

    window.addEventListener("orientationchange", onResize)
    return () => document.removeEventListener("orientationchange", onResize)
  }, [])
  
  useEffect(() => {
    if (!videoRef.current || !canvasRef.current || !imageRef.current) return
    const videoElement = videoRef.current
    const canvasElement = canvasRef.current
    // const imageElement = imageRef.current

    const gl = new GL(canvasElement)
    store.gl = gl
    const imageProcessor = new ImageProcessor()
    store.imageProcessor = imageProcessor
    const init = async () => {
      await initCamera(videoElement)
      await waitCameraData(videoElement)
      await new Promise(res => setTimeout(res, 20))
      await imageProcessor.init(videoElement)
      canvasElement.width = videoElement.videoWidth
      canvasElement.height = videoElement.videoHeight
      
      // await imageProcessor.init(imageElement)
      // canvasElement.width = imageElement.naturalWidth
      // canvasElement.height = imageElement.naturalHeight
      
      gl.onResize()
      gl.initVideoBackground(imageProcessor.canvas)
      imageProcessor.process(({ imageData, homography, fovAngle, fps, data }) => {
        // (document.getElementById("fps") as HTMLElement).innerHTML = "fps: " + fps.toFixed()
        
        store.setData(data)

        if (homography === null)
          return gl.render()

        const cameraMatrix = getCameraMatrix(imageData.height, imageData.width, fovAngle)
        let decomposedMatrix = decompose(homography, cameraMatrix)
        gl.updateCameraMatrix(fovAngle, decomposedMatrix)
        gl.render()
      })
    }
    
    init()
    return () => {
      imageProcessor.stop()
    }

  }, [ store ])

  return (
    <div className="App">
      <canvas ref={canvasRef} className="canvas"></canvas>
      <video ref={videoRef} muted={true} playsInline={true} style={{display: "none"}}/>
      <img ref={imageRef} src="/test-images/test-11.jpg" alt="test" style={{display: "none"}}/>
      <div id="fps" style={{ position: "absolute", right: "10px", top: "10px" }}/>
      <PlayButton store={store} />
    </div>
  );
}

export default App;
