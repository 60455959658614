import { multiply, transpose } from "mathjs"


export function lerp(startFloat: number, endFloat: number, value: number) {

  return startFloat + (endFloat-startFloat)*EasingFunctions.easeInOutQuad(value)
}

export const EasingFunctions = {
  // no easing, no acceleration
  linear: (t: number) => t,
  // accelerating from zero velocity
  easeInQuad: (t: number) => t*t,
  // decelerating to zero velocity
  easeOutQuad: (t: number) => t*(2-t),
  // acceleration until halfway, then deceleration
  easeInOutQuad: (t: number) => t<.5 ? 2*t*t : -1+(4-2*t)*t,
  // accelerating from zero velocity 
  easeInCubic: (t: number) => t*t*t,
  // decelerating to zero velocity 
  easeOutCubic: (t: number) => (--t)*t*t+1,
  // acceleration until halfway, then deceleration 
  easeInOutCubic: (t: number) => t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1,
  // accelerating from zero velocity 
  easeInQuart: (t: number) => t*t*t*t,
  // decelerating to zero velocity 
  easeOutQuart: (t: number) => 1-(--t)*t*t*t,
  // acceleration until halfway, then deceleration
  easeInOutQuart: (t: number) => t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t,
  // accelerating from zero velocity
  easeInQuint: (t: number) => t*t*t*t*t,
  // decelerating to zero velocity
  easeOutQuint: (t: number) => 1+(--t)*t*t*t*t,
  // acceleration until halfway, then deceleration 
  easeInOutQuint: (t: number) => t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t
}

export function sqrHypot(a: number, b: number) {
  return a*a + b*b
}

export function mul(a: number[][], b: number[][]) {
  let resp = multiply(a, b)
  for (let row of resp) {
    for (let i = 0; i < row.length; i++) {
      row[i] = row[i] / row[row.length-1]
    }
  }
  return resp
}

export function coordMultiply(h: number[][], b: number[][]) {
  return transpose(multiply(h, transpose(b)))
}