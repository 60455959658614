export const makeCanvasResizable = (canvas: HTMLCanvasElement, onResize?: () => void) => {

  let timeoutId: number | null = null
  const resize = () => {
    if (timeoutId) window.clearTimeout(timeoutId)
    
    timeoutId = window.setTimeout(() => {
      timeoutId = null
      if (onResize) onResize()
    }, 80)
  }
  resize()

  window.addEventListener("resize", resize)
  return () => {
    window.removeEventListener("resize", resize)
  }
}

export const coords = ({ x, y }: { x: number, y: number }, canvas: HTMLCanvasElement) => {

  const rect = canvas.getBoundingClientRect()
  const k = Math.max(rect.width/canvas.width, rect.height/canvas.height)
  const _x = ((x-rect.left) * 2 - rect.width)/(canvas.width * k)
  const _y = (-(y-rect.top) * 2 + rect.height)/(canvas.height * k)

  return { x: _x, y: _y }
}

export const offset = ({ x, y }: { x: number, y: number }, canvas: Element) => {
  const rect = canvas.getBoundingClientRect()
  return { x: x + rect.left, y: y + rect.top }
}

export const _coords = ({ x, y }: { x: number, y: number }, canvas: Element) => {
  const rect = canvas.getBoundingClientRect()
  const _x = (x * 0.5 + 0.5) * rect.width + rect.left
  const _y = (-y * 0.5 + 0.5) * rect.height + rect.top

  return { x: _x, y: _y }
}