import * as THREE from 'three'

type Point = { x: number, y: number }

export function pointInFigure (pos: number[], points: number[][]): boolean {

  let a = 0
  for(let i = 0; i < points.length; i++) {
    let j = (i < points.length-1)? i+1: 0
    const v = [ points[j][0]-points[i][0], points[j][1]-points[i][1] ]
    const v2 = [ pos[0]-points[i][0], pos[1]-points[i][1] ]

    const _a = v[0]*v2[1] - v[1]*v2[0]

    if (a === 0 && _a !== 0) a = _a
    if (a > 0 && _a < 0) return false
    if (a < 0 && _a > 0) return false
  }

  return true
}

export function getCenter(points: Point[]) {
  const p = { x: 0, y: 0 }
  for (let point of points) {
    p.x += point.x
    p.y += point.y
  }
  return { x: p.x/points.length, y: p.y/points.length } 
}

export function getRoot(obj: THREE.Object3D): THREE.Object3D | null {
  if (obj.userData.onClick) return obj
  if (!obj.parent) return null
  return getRoot(obj.parent)
}

export function getTop(object: THREE.Object3D) {
  const box = new THREE.Box3().setFromObject(object) 
  return box.max.y
}

export function getRotationMatrix(object: THREE.Object3D, angle: number) {
  let box = new THREE.Box3().setFromObject(object) 

  const center = new THREE.Vector3()
  box.getCenter(center)

  const rotationMatrix = new THREE.Matrix4()
  rotationMatrix.makeRotationFromEuler(new THREE.Euler(0, angle * THREE.MathUtils.DEG2RAD, 0))

  const matrix = new THREE.Matrix4()
  .makeTranslation(-center.x, -center.y, -center.z)
  .premultiply(rotationMatrix)
  .premultiply(new THREE.Matrix4().makeTranslation(center.x, center.y, center.z))

  return matrix
}

export function getScaleMatrix(object: THREE.Object3D, scale: number) {
  let box = new THREE.Box3().setFromObject(object) 

  const center = new THREE.Vector3()
  box.getCenter(center)

  const scaleMatrix = new THREE.Matrix4()
  scaleMatrix.scale(new THREE.Vector3(scale, scale, scale))

  const matrix = new THREE.Matrix4()
  .makeTranslation(-center.x, -box.min.y, -center.z)
  .premultiply(scaleMatrix)
  .premultiply(new THREE.Matrix4().makeTranslation(center.x, box.min.y, center.z))

  return matrix
}

export function applyUserData(obj: THREE.Object3D) {
  if (obj.userData.scale) {
    obj.scale.set(obj.userData.scale, obj.userData.scale, obj.userData.scale)
  }

  if (obj.userData.rotation) {
    const matrix = getRotationMatrix(obj, obj.userData.rotation)
    obj.applyMatrix4(matrix)
  }
}