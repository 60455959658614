export async function initCamera(video: HTMLVideoElement){

  const stream = await navigator.mediaDevices.getUserMedia({
    audio: false,
    video: {
      facingMode: 'environment',
      width: 720
    },
  })

  video.srcObject = stream
  video.play()
  
  return stream
}

export const waitCameraData = (videoElement: HTMLVideoElement, readyState = 4) => new Promise<void>((res, rej) => {
	let count = 0
	const interval = setInterval(() => {
		if(videoElement.readyState >= readyState){
			clearInterval(interval)
			res()
		}
		count++
		if(count === 50){
			clearInterval(interval)
			rej("Wrong HMTL5 video")
		}
	}, 50)
})


export function initCanvas (width: number, height: number){

	const canvas = document.createElement('canvas')
	canvas.width = width
	canvas.height = height

	const ctx = canvas.getContext('2d')
	if(!ctx) throw new Error("Can`t get context on canvas")

	return {
		getImageData: (mediaElement: HTMLVideoElement | HTMLImageElement) => {
			ctx.drawImage(mediaElement, 0, 0, canvas.width, canvas.height)
			const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
			return imageData
		},
		canvas
	}
}