import { observer } from "mobx-react-lite";
import cn from 'classnames'
import Store from "store";

const PlayButton = observer(({ store }: { store: Store }) => {

  return (
    <button className={cn("button-play", !store.project?.useUiButton && "hidden")} onClick={() => store.project!.playAr()}>
      Play AR
    </button>
  )

})

export default PlayButton