import * as THREE from 'three'

export function flatVector(start: { x: number, y: number }, target: { x: number, y: number }, height: number, camera: THREE.Camera) {
  const plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), -height)
  const raycaster = new THREE.Raycaster();
  
  raycaster.setFromCamera(new THREE.Vector2(start.x, start.y), camera)
  const startPoint = new THREE.Vector3()
  raycaster.ray.intersectPlane(plane, startPoint)

  raycaster.setFromCamera(new THREE.Vector2(target.x, target.y), camera)
  const targetPoint = new THREE.Vector3()
  raycaster.ray.intersectPlane(plane, targetPoint)

  return targetPoint.sub(startPoint)
}

export const cvToGlMatrix = new THREE.Matrix4().set(
	1, 0, 0, 0,
  0, -1, 0, 0,
	0, 0, -1, 0,
	0, 0, 0, 1
)

export const rotationMatrix = new THREE.Matrix4().makeRotationX(-90 * THREE.MathUtils.DEG2RAD)