import * as THREE from 'three'
import { Object3D } from 'three'

type Opts = {
  position: { x:number, y: number, z: number },
  rotation: number,
  scale: number
}

export const transformMatrix = (obj: Object3D, { position, rotation, scale }: Opts) => {

  const box = new THREE.Box3().setFromObject(obj)
  const center = box.getCenter(new THREE.Vector3()).setY(box.min.y).negate()

  const radAngle = -rotation * THREE.MathUtils.DEG2RAD
  const matrix = new THREE.Matrix4()

  matrix.premultiply(new THREE.Matrix4().setPosition(center))
  matrix.premultiply(new THREE.Matrix4().makeRotationFromEuler(new THREE.Euler(0, radAngle, 0)))
  matrix.premultiply(new THREE.Matrix4().scale(new THREE.Vector3(scale, scale, scale)))

  center.negate().add(new THREE.Vector3(position.x, -box.min.y + position.y, position.z))
  matrix.premultiply(new THREE.Matrix4().setPosition(center))

  return matrix
}